'use strict';
var isLimitReached = false;
var tealiumEnable = require('int_tealium_sfra/tealiumEnable');
var utils = require('../utils');
var constants = require('../constants');
var merge = require('lodash/merge');
var omit = require('lodash/omit');
var currentRefinementContainerScrollPos = 0;
var $loaderContainer = $('body');
var scrollHeightOffset = $('#headerComponent').outerHeight();
let fetchNextInProgress = false;

var pageSize = $('input[name="pageSize"]').val();
var loadMoreCount = $('input[name="loadMoreCount"]').val();
var isMobile = utils.getCurrentBreakpoint() === 'mobile';
var deviceType = utils.getCurrentBreakpoint() === 'mobile' ? 'mobile' : 'desktop';

var isFilterUpdate = false;

var refinebarhelper = require('../helpers/refinebarHelper');


/**
 * Define loader container based on device type
 * @param {boolean} overlay should open
 */
function setLoaderContainer() {
    // set to true if the click happen inside filter overlay
    const isOverlay = $('.refinements, .clearall-view').length && isMobile;

    if (isOverlay) {
        $loaderContainer = $('.filter-open.left-filter');
    } else {
        $loaderContainer = $('.search-page:first');
    }
}

/**
 * Scroll PLP page when refinments/sort is clicked
 */
function handleRefinementChange() {
    window.scroll({ top: $('.js-search-results').offset().top - scrollHeightOffset, left: 0, behavior: 'smooth' });
}

/**
 * Update DOM elements with Ajax results
 *
 * @param {Object} $results - jQuery DOM element
 * @param {string} selector - DOM element to look up in the $results
 * @return {undefined}
 */
function updateDom($results, selector) {
    var $updates = $results.find(selector);
    $(selector).empty().html($updates.html());
}

/**
 * Initialize PD script
 * @param {Object} response - jQuery DOM element
 */
function initializePageDesignerScript(response) {
    let $productListPD = $(response).find('.experience-dynamic-productList');
    if ($productListPD.length) {
        $('body').trigger('pagedesigner:initComponent');
    }
}

/**
 * Keep refinement panes expanded/collapsed after Ajax refresh
 *
 * @param {Object} $results - jQuery DOM element
 * @param {function} cb callback function
 * @return {undefined}
 */
function handleRefinements($results, cb) {
    $('.refinement.active').each(function () {
        $(this).removeClass('active');
        var activeDiv = $results.find('.' + $(this)[0].className.replace(/ /g, '.'));
        activeDiv.addClass('active');
        activeDiv.find('button.title').attr('aria-expanded', 'true');
    });


    updateDom($results, '.refinements');
    $('.refinements').css('opacity', '0');

    if (typeof cb === 'function') {
        cb();
    }
}

/**
 * update refinements container scroll pos after reload.
 * @param {number} currentPos current scroll position
 */
function updateRefinementContainerPos(currentPos) {
    var $refinementContainer = $('.filter-open .ds-sort-filter.ds-drawers')[0];

    if (utils.getCurrentBreakpoint().indexOf('desktop') >= 0) {
        $refinementContainer = $('.ds-filter-inline .ds-left-filter')[0];
    }

    if (currentPos && $refinementContainer) {
        $refinementContainer.scrollTop = currentPos;
    } else if ($refinementContainer) {
        currentRefinementContainerScrollPos = $refinementContainer.scrollTop;
    }
}

/**
 * Parse Ajax results and updated select DOM elements
 *
 * @param {string} response - Ajax response HTML code
 * @return {undefined}
 */
function parseResults(response) {
    updateRefinementContainerPos();

    var $results = $(response);
    var specialHandlers = {
        '.refinements': handleRefinements
    };

    // Update DOM elements that do not require special handling
    [
        '.grid-header',
        '.search-result-count',
        '.search-count',
        '.view-filter',
        '.header-bar',
        '.header.page-title',
        '.product-grid',
        '.show-more',
        '.filter-bar',
        '.sort-by-main',
        '.ds-show-filters-btn-mobile',
        '.no-results-container',
        '.no-search-results',
        '.ds-main-heading',
        '.seo-content-container--title',
        '.ds-l1-categories'
    ].forEach(function (selector) {
        updateDom($results, selector);
    });

    Object.keys(specialHandlers).forEach(function (selector) {
        specialHandlers[selector]($results, () => {
            setTimeout(() => {
                updateRefinementContainerPos(currentRefinementContainerScrollPos);
                $(selector).css('opacity', '1');
            }, 10);
        });
    });
}

/**
 * This function retrieves another page of content to display in the content search grid
 * @param {JQuery} $element - the jquery element that has the click event attached
 * @param {JQuery} $target - the jquery element that will receive the response
 * @return {undefined}
 */
function getContent($element, $target) {
    var showMoreUrl = $element.data('url');
    $.spinner().start();
    $.ajax({
        url: showMoreUrl,
        method: 'GET',
        success: function (response) {
            $target.append(response);
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * This function appending the paramets on load products on plp
 * @param {JQuery} url - the jquery element that has the click event attached
 */
function captureScrollOnProductTile(url) {
    var pageUrl = new URL(window.location);
    var scrolledUrl = utils.getQueryStringParams(url);
    var size = scrolledUrl.sz;
    var start = scrolledUrl.start;
    var tot = parseInt(start, 10) + parseInt(size, 10);
    var temp = pageUrl;
    temp.searchParams.set('sz', tot);
    temp.searchParams.set('start', 0);
    window.history.replaceState(null, '', temp);
}

/**
 *  Keep accordians open on category carasel filter selection
 */
function handleAccordionOnCategoryCarousel() {
    let openAccordianValues = [];
    if (window.sessionStorage.getItem('filterState') !== null) {
        openAccordianValues = JSON.parse(window.sessionStorage.getItem('filterState'));
    }
    if (openAccordianValues) {
        openAccordianValues.forEach(element => {
            let $filter = $('.enable-drawer[data-refinementname="' + element + '"]');
            $filter.find('.accordionHeader button.btn')
            .removeClass('collapsed')
            .attr('aria-expanded', 'true');
            $filter.find('.content').addClass('show');
        });
    }
}

/**
 * Unwrap next PLP batches from PD classes
 */
function unwrapPlpBatches() {
    let $notFirstBatch = $('.notFirstBatch');
    if ($notFirstBatch){
        $('.notFirstBatch').each(function(){
            let tile = $(this).find('.productTileShow');
            let batchWrapper = $(this).find('.experience-dynamic-productList');
            batchWrapper.unwrap();
            tile.unwrap();
        });
    }

    // mount quickview component for new tiles loaded
    window.PVH.mountAllComponents('quickView');
}
/**
* PLP batch URL Handling
 * @param {string} url -
 * @return {function} - success function or error function
 */
function fetchPlpBatch(url) {
    return $.ajax({
        url: url,
        method: 'GET',
        success: function (response) {
            $('.plp-loader').addClass('d-none');
            $('.grid-footer').replaceWith(response);
            $(document).trigger(constants.events.PLP_PRODUCT_TILE_UPDATE);
            unwrapPlpBatches();
            initializePageDesignerScript(response);
        },
        error: function () {
        }
    });
}

/**
 * Scroll to product in PLP
 * @param {number} scrollPosition Scroll position number
 */
function scrollToProduct(scrollPosition) {
    if (scrollPosition) {
        utils.scrollWithoutAnimation(0, scrollPosition);
        utils.resetPLPScrollPos();
    }
}

/**
 * Set plp scroll pos when clicking on a product
 * @param {number} productIndex productindex from plp
 */
function setPLPScrollPos(productIndex) {
    window.sessionStorage.setItem('plpUrl', window.location.pathname);
    window.sessionStorage.setItem('plpScrollY', window.scrollY);
    window.sessionStorage.setItem('productIndex', productIndex);
}

/**
 * Remove loaders
 */
function removeLoaders() {
    $('body').spinner().stop();
    $('html').removeClass('hide-scroll-and-lock');
}

/**
 * selected L4 category name styling
 */
function l4CategorySelected() {
    var breadcrumb = $('.plp-page .ds-breadcrumb ul li:last-child a');
    var l4CategoryLists = $('.js-l4-category');
    if (breadcrumb.length && l4CategoryLists.length) {
        $.each(l4CategoryLists, function (l4CategoryIndex, l4CategoryList) {
            var selectedL4Cat = l4CategoryList.innerText;
            if (breadcrumb[0].innerText.trim() === selectedL4Cat) {
                var $shopAllElemet = $('#l4-category-shop-all');
                $shopAllElemet.addClass('l4-cat-name-grey');
                $(this).addClass('l4-cat-name-black');
            }
        });
    }
}

function initializeCategoryFilter(CategoryTileurl, $filterElem) {
    var $productGrid = $('.search-results');
    $('.search-page').spinner().start(true);

    $.ajax({
        url: CategoryTileurl,
        data: {
            page: $('.grid-footer').data('page-number')
        },
        method: 'GET',
        success: function (response) {
            parseResults(response);
            handleAccordionOnCategoryCarousel();
            setTimeout(() => {
                $('.search-page').spinner().stop();
            }, 300);
            var pageUrl = new URL(window.location);
            var tempUrl = new URL(pageUrl.origin + CategoryTileurl);
            tempUrl.searchParams.delete('cgid');
            pageUrl.search = tempUrl.search;
            window.history.replaceState(null, '', pageUrl);
            handleRefinementChange();
            $(document).trigger(constants.events.SEARCH_FILTER_CHANGE);
            $filterElem.toggleClass('selectedCategory');
            $('.product-grid .productTileShow').removeClass('producttile-transparency');
            isFilterUpdate = true;
            initializePageDesignerScript(response);
        },
        error: function () {
            $filterElem.toggleClass('size--selectable size--selected');
            $('.search-page').spinner().stop();
        }
    });
}

/**
 *  Replace Existing Sorting Rule
 * @param {string} previousUrl - Previous Url
 * @param {string} pmin - min price to be added to url
 * @param {string} pmax - max price to be added to url
 * @returns {string} New url containing new sorting rule
 */
function updatePriceRange(previousUrl, pmin, pmax) {
    var params = utils.getQueryStringParams(previousUrl.split('?')[1]);
    params.pmin = pmin.toFixed(2);
    params.pmax = pmax.toFixed(2);
    var orginialUrl = previousUrl ? previousUrl.split('?')[0] : window.location.href;
    var newUrl = utils.appendToUrl(orginialUrl, params);
    return newUrl;
}

function applySortAjax(selectedSortAttr, $productGrid) {
    $.ajax({
        url: selectedSortAttr,
        method: 'GET',
        success: function (response) {
            let $productListPD = $(response).find('.experience-dynamic-productList');
            if ($productListPD.length) {
                // Fetching product list when page desinger page is returned
                $('.product-grid').empty().html($productListPD.html());
                $('body').trigger('pagedesigner:initComponent');
            } else {
                $('.product-grid').empty().html(response);
            }
            $loaderContainer.spinner().stop();
            var pageUrl = new URL(window.location);
            var tempUrl = new URL(selectedSortAttr);
            tempUrl.searchParams.delete('cgid');
            let selectedId = tempUrl.searchParams.get('srule');
            var isNonIndexableURL = pageUrl.search.includes('index=0') ? true : false;
            pageUrl.search = tempUrl.search;
            if (isNonIndexableURL) {
                pageUrl.searchParams.set('index', '0');
            }
            window.history.replaceState(null, '', pageUrl);

            // Adobe Analytics - Tealium
            tealiumEnable.getAnalyticsData('sortEvent', selectedId);
            handleRefinementChange();
            $(document).trigger(constants.events.SEARCH_FILTER_CHANGE);
            $('.product-grid .productTileShow').removeClass('producttile-transparency');
            isFilterUpdate = true;

            // mount quickview component for new tiles loaded
            window.PVH.mountAllComponents('quickView');
        },
        error: function () {
            $loaderContainer.spinner().stop();
        }
    });
}

/**
 * Get PLP scroll position saved before
 * @returns {Object} PLP scroll position details
 */
function getPLPScrollPos() {
    const pageUrl = window.sessionStorage.getItem('plpUrl');
    const isOnSamePLPPage = window.location.pathname === pageUrl;

    // check if current page and previous plp page are the same, if not forget position
    const scrollPosition = isOnSamePLPPage ? window.sessionStorage.getItem('plpScrollY') : 0;
    const productIndex = isOnSamePLPPage ? window.sessionStorage.getItem('productIndex') : 0;

    return {
        scrollPosition,
        productIndex
    };
}

module.exports = {
    onLoad: function () {
        // styling the selected L4 category name -- dynamic Subcategory List
        l4CategorySelected();
        const { scrollPosition } = getPLPScrollPos();

        history.scrollRestoration = 'manual';

        var batchSize = $('input[name="batchSize"]').val();
        var numberOfProducts = utils.getQueryStringParams(window.location.href).sz;
        // eslint-disable-next-line
        var pages = parseInt(numberOfProducts) / parseInt(batchSize);
        var newPromise = $.when();
        // eslint-disable-next-line
        let start = parseInt(batchSize);
        let url = $('input[name="urlshowmore"]').val() !== '' ? $('input[name="urlshowmore"]').val() + '&deviceType=' + deviceType : $('input[name="urlshowmore"]').val();
        let count = 1;

        var currentURL = window.location.href;
        if (currentURL.indexOf('pmin=') === -1 && currentURL.indexOf('pmax=') === -1) {
            window.sessionStorage.removeItem('selectedPriceFilters');
        }

        refinebarhelper.handlePriceFilter();

        /**
         * Create PLP search URL and fetch product
         * @return {Object} -
         */
        const createUrlAndFetchPLP = () => {
            var params = utils.getQueryStringParams(url.split('?')[1]);
            params.start = start * count;
            url = url.split('?')[0];
            url = utils.appendToUrl(url, params);
            count++;
            return fetchPlpBatch(url);
        };

        /**
         * Handle PLP fetch response
         */
        const handlePLPResponse = () => {
            // check if number of request is complete to scroll
            if (count >= pages) {
                try {
                    removeLoaders();
                    scrollToProduct(scrollPosition);
                } catch (error) {
                    console.error(error);
                }
                $('.product-grid').removeClass('loading');
            }
        };

        try {
            if (pages > 1) {
                $('body').spinner().start();
                $('html').addClass('hide-scroll-and-lock');
                setTimeout(() => $('.product-grid').addClass('loading'), 100);
                for (let index = 1; index < pages; index++) {
                    newPromise = newPromise
                        .then(() => createUrlAndFetchPLP())
                        .done(() => handlePLPResponse());
                }
            } else {
                removeLoaders();
            }
        } catch (err) {
            removeLoaders();
        }
    },
    closeSort: function () {
        $('body').click(function (e) {
            if (!$(e.target).closest('.sort-by-main').length) {
                $('.plp-sort-order-options').addClass('d-none');
            }
        });
    },

    resize: function () {
        // Close refinement bar and hide modal background if user resizes browser
        $(window).resize(function () {
            $('.refinement-bar, .modal-background').hide();
            $('.refinement-bar').siblings().attr('aria-hidden', false);
            $('.refinement-bar').closest('.row').siblings().attr('aria-hidden', false);
            $('.refinement-bar').closest('.tab-pane.active').siblings().attr('aria-hidden', false);
            $('.refinement-bar').closest('.container.search-results').siblings().attr('aria-hidden', false);
        });
    },

    desktopSort: function () {
        // Handle sort order menu selection
        $('body').on('change', '.sort-by-main select', function (e) {
            e.preventDefault();

            var selectedSortAttr = this.value;
            var $productGrid = $('.search-results');

            let $sortUnChecked = $('.sort-menu-item.selected .sort-unchecked');
            let $sortChecked = $('.sort-menu-item.selected .sort-checked');

            $sortUnChecked.toggleClass('d-none');
            $sortChecked.toggleClass('d-none');
            $('.sort-menu-item.selected').removeClass('selected');
            var tempUrl = new URL(selectedSortAttr);
            let selectedId = tempUrl.searchParams.get('srule');
            $(`.sort-menu-item.${selectedId}`).addClass('selected');
            $sortUnChecked.toggleClass('d-none');
            $sortChecked.toggleClass('d-none');

            setLoaderContainer();

            updateRefinementContainerPos();
            $loaderContainer.spinner().start(true);
            $('.product-grid .productTileShow').addClass('producttile-transparency');
            $(this).trigger('search:sort', selectedSortAttr);

            applySortAjax(selectedSortAttr, $productGrid);
        });
    },

    sort: function () {
        // Handle sort order menu selection
        $('.container, .ds-search-results').on('click', '.sort-menu-item', function (e) {
            e.preventDefault();

            var selectedSortAttr = $(this).attr('data-value');
            var $productGrid = $('.search-results');

            var selectedId = $(this).attr('data-id');
            var $sortMenuItem = $('.sort-menu-item');
            var $sortUnChecked = $('.sort-unchecked');
            var $selectedItem = $(`.sorting-section .sort-menu-item[data-id='${selectedId}']`);
            var $selectedItemRadio = $selectedItem.children('.sort-checked');
            setLoaderContainer();

            updateRefinementContainerPos();
            $loaderContainer.spinner().start(true);
            $('.product-grid .productTileShow').addClass('producttile-transparency');
            $(this).trigger('search:sort', selectedSortAttr);


            $('.selected-sort-label').text($(this).children('.sort-menu-label').text());
            $('.plp-sort-order-options').addClass('d-none');
            $('.selected-sort-label').text($(this).children('.sort-menu-label').text());

            let pageUrl = new URL(window.location);
            let prevSelectedId = pageUrl.searchParams.get('srule');
            $(`.sort-dropdown .${prevSelectedId}`).removeAttr('selected');
            $(`.sort-dropdown .${selectedId}`).attr('selected', 'selected');

            // updating selected sort before Ajax response
            $sortMenuItem.each(function () {
                let $sortChecked = $(this).find('.sort-checked');
                $(this).removeClass('selected');
                $sortChecked.addClass('d-none');
            });
            $sortUnChecked.each(function () {
                $(this).removeClass('d-none');
            });
            $(this).children('.sort-checked').toggleClass('d-none');
            $(this).children('.sort-unchecked').toggleClass('d-none');
            $selectedItem.addClass('selected');
            $selectedItem.children('.sort-unchecked').addClass('d-none');
            $selectedItemRadio.removeClass('d-none');

            applySortAjax(selectedSortAttr, $productGrid);
        });
    },

    sortclick: function () {
        $(document).on('click', '.plp-sort-order-btn', function () {
            $('.plp-sort-order-options').toggleClass('d-none');
        });
    },
    showMore: function () {
        // Show more products
        $('.container, .ds-search-results').on('click', '.show-more button', function (e) {
            e.stopPropagation();
            var showMoreUrl = $(this).data('url') !== '' ? $(this).data('url') + '&deviceType=' + deviceType : $(this).data('url');
            $('.plp-loader').removeClass('d-none');
            e.preventDefault();

            $(this).trigger('search:showMore', e);
            $.ajax({
                url: showMoreUrl,
                method: 'GET',
                success: function (response) {
                    $('.plp-loader').remove();
                    captureScrollOnProductTile(showMoreUrl);
                    $('.grid-footer').replaceWith(response);
                    $.spinner().stop();
                    $(document).trigger(constants.events.PLP_PRODUCT_TILE_UPDATE);
                    unwrapPlpBatches();
                    isLimitReached = false;
                },
                error: function () {
                    $('.plp-loader').addClass('d-none');
                }
            });
        });
    },

    categorySlick: function () {
        const $categoryList = $('.categoryList');
        if (!$categoryList.length) return;

        //For Category Carousel with less than four categories
        var $categoryImages = $(".categoryList img").length;
        var screenWidth = window.innerWidth;
        if($categoryImages < 6 && screenWidth > 1919){
            utils.initializeSlider($categoryList, {autoplay: false,
                slidesToShow: 4,
                adaptiveHeight: true,
            });
        } else {
            utils.initializeSlick();
        }
    },

    selectedCategory: function () {
        $('.filterTile[data-selected="true"]').addClass('selectedCategory');
    },

    applyFilterCategory: function () {
        // Handle refinement value selection and reset click
        $(document).on(
            'click',
            '.filterTile',
            function (e) {
                e.preventDefault();
                e.stopPropagation();
                setLoaderContainer();

                var $filterElem = $(this);
                var sortingRule = $('.sort-menu-item.selected').attr('data-id');
                var filterButtonUrl = $filterElem.data('href');

                var urlParamsObj = utils.getUrlParamAsObject(location.href).urlParmas;
                var noPrefObj = utils.getUrlParamAsObject(location.href).nonPref;
                var updateObj = utils.updateURLValues($filterElem.data('refinementquery'));
                var cgidAjax  = $filterElem.data('cgid');
                var selected = $filterElem.data('selected');

                // Removing start and sz parameters when filterTile is clicked
                var paramsToExclude = ['start', 'sz'];
                noPrefObj = omit(noPrefObj, paramsToExclude);

                noPrefObj.cgid = cgidAjax;
                if (selected) {
                    var URLparamsSerialized = utils.updateURLParams(urlParamsObj, noPrefObj, updateObj, false);
                    $filterElem.data('selected', false);
                    for (let key in updateObj) {
                        if (key=='pmin'||key=='pmax'){
                            URLparamsSerialized = omit(URLparamsSerialized, 'pmin', 'pmax');
                        }
                    }
                } else {
                    var URLparamsSerialized = utils.updateURLParams(urlParamsObj, noPrefObj, updateObj, true);
                    $filterElem.data('selected', true);
                }

                if (sortingRule) {
                    URLparamsSerialized['srule'] = sortingRule;
                }

                var CategoryTileurl = utils.appendToUrl(filterButtonUrl.split('?')[0], URLparamsSerialized);

                $filterElem.trigger('search:filter', e);
                initializeCategoryFilter(CategoryTileurl, $filterElem);
            });
    },

    showContentTab: function () {
        // Display content results from the search
        $('.container').on('click', '.content-search', function () {
            if ($('#content-search-results').html() === '') {
                getContent($(this), $('#content-search-results'));
            }
        });

        // Display the next page of content results from the search
        $('.container').on('click', '.show-more-content button', function () {
            getContent($(this), $('#content-search-results'));
            $('.show-more-content').remove();
        });
    },

    pdpFromPlp: function () {
        $(document).on('click', '.plp-img-container, .pdp-link .ds-product-name', function () {
            var productTile = $(this);
            var searchRedirect = $(this).data('searchcheck');
            setPLPScrollPos($(this).closest('.productTileShow').data('productindex'));
            tealiumEnable.getAnalyticsData('navToPDPFromPLPAnalytics', productTile, searchRedirect);
        });
    },

    pageBreadcrumb: function () {
        $('.ds-breadcrumb a').on('click', function () {
            var hrefLink = $(this).attr('href');
            var linkText = $(this).text().trim();
            tealiumEnable.getAnalyticsData('breadcrumbAnalytics', hrefLink, linkText);
        });
    },

    horizontalscrollMenuLoad: function () {
        var $horizontalscrollMenu = $('.ds-plp-page').find('.ds-similier-pages');
        if ($horizontalscrollMenu.length) {
            let $horizontalMenuElement = $horizontalscrollMenu.find('.category-bold');
            if ($horizontalMenuElement.length) {
                $horizontalMenuElement[0].scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest',
                    inline: 'start',
                });
            }
        }
    }
};


/**
 * Get next set of products from Ajax response
 */
function fetchNextBatch() {
    // check if already running process
    if (fetchNextInProgress) {
        return;
    }

    var showMoreUrl = $('input[name="urlshowmore"]').val() !== '' && typeof $('input[name="urlshowmore"]').val() !== 'undefined' ? $('input[name="urlshowmore"]').val() + '&deviceType=' + deviceType : $('input[name="urlshowmore"]').val();
    isLimitReached = false;
    $('.plp-loader').removeClass('d-none');
    if (!showMoreUrl || (showMoreUrl === '') || (showMoreUrl && showMoreUrl.split('start=')[1].split('&')[0] % (pageSize * loadMoreCount) === 0)) {
        isLimitReached = true;
        $('.plp-loader').addClass('d-none');
    }


    if (!isLimitReached) {
        fetchNextInProgress = true;
        $.ajax({
            url: showMoreUrl,
            method: 'GET',
            success: function (response) {
                $('.plp-loader').remove();
                captureScrollOnProductTile(showMoreUrl);
                $('.grid-footer').replaceWith(response);
                $(document).trigger(constants.events.PLP_PRODUCT_TILE_UPDATE);
                unwrapPlpBatches();
                initializePageDesignerScript(response);
                fetchNextInProgress = false;
            },
            error: function () {
                fetchNextInProgress = false;
            }
        });
    }
}

if ('IntersectionObserver' in window) {
    // if browser supports IntersectionObserver
    document.addEventListener('DOMContentLoaded', () => {
        var options = {
            root: null,
            rootMargin: '0px 0px 2000px 0px',
            threshold: 0
        };
        /**
        * Calls next batch of products when intersection is true
        * @param {array} entries on the viewport
        */
        function handleIntersect(entries) {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    fetchNextBatch();
                }
            });
        }
        let observer = new IntersectionObserver(handleIntersect, options);
        var loader = document.querySelector('#footercontent');
        observer.observe(loader);
    });
} else {
    // usual window scroll
    $(window).on('load', function () {
        var defaultHeight = (($('#maincontent').offset().top + $('#maincontent').outerHeight()) - window.innerHeight);
        var limitHeight = defaultHeight;

        $(window).scroll(function () {
            if ($(window).scrollTop() > (limitHeight) && !isLimitReached) {
                limitHeight += defaultHeight;
                fetchNextBatch();
            }
        });
    });
}
