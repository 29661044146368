const constants = require('./constants');

let $body = document.querySelector('body');
let scrollPosition = 0;
let scrollY = 0;
const preferenceKeyPrefix = 'prefn';
const preferenceValuePrefix = 'prefv';
let toastTimeoutInstance;

var merge = require('lodash/merge');
var isEmpty = require('lodash/isEmpty');

/**
 *
 * Obtains the view-port height and multiplies it by 1% to get a value for a vh unit
 * After that the value in the --vh custom property to the root of the document is set
 */
function setWindowHeight() {
    setTimeout(() => {
        document.documentElement.style.setProperty('--vh', `${window.innerHeight}px`);
    }, 100);
}

/**
 * Toggle interaction mode between keyboard and mouse to control focus
 */
function toggleInteractionMode() {
    let $bodyElem = $('body');

    $bodyElem.addClass('no-focus-outline');
    $('#nav-main').removeClass('tab-focus-outline');

    $bodyElem.on('mousedown', function () {
        $(this).addClass('no-focus-outline');
        $('#nav-main').removeClass('tab-focus-outline');
    });

    $bodyElem.on('keyup', function (e) {
        if (e.code === 'Tab') {
            $(e.currentTarget).removeClass('no-focus-outline');
            $('#nav-main').addClass('tab-focus-outline');
        }
    });
}

/**
 * check if the site is running on touch enabled device.
 * @returns {boolean} touch enabled boolean
 */
function isTouchEnabled() {
    return ('ontouchstart' in window) ||
        (navigator.maxTouchPoints > 0) ||
        (navigator.msMaxTouchPoints > 0);
}

/**
 * Lock page scroll
 */
function lockPage() {
    $body = $body ? $body : document.querySelector('body');
    scrollPosition = window.pageYOffset;
    $body.style.overflow = 'hidden';
    $body.style.position = 'fixed';
    $body.style.top = `-${scrollPosition}px`;
    $body.style.width = '100%';
}

/**
 * Unlock page scroll
 */
function unlockPage() {
    $body = $body ? $body : document.querySelector('body');
    $body.style.removeProperty('overflow');
    $body.style.removeProperty('position');
    $body.style.removeProperty('top');
    $body.style.removeProperty('width');
    window.scrollTo(0, scrollPosition);
}

/**
 * get last saved window scroll pos
 * @returns {number} last window scroll position
 */
function getScrollPos() {
    return scrollY || window.scrollY;
}

/**
 * set current window scoll pos for later
 */
function setScrollPos() {
    scrollY = window.scrollY;
}

/**
 * Function to handle maximum length of characters of firstname & lastname in phone
 * @param {jQuery} $elem - Input string
 * @param {number} maxlength - Maximum length upto which user can enter the characters for firstname & lastname
 */
function formElementLimitCheck($elem) {
    var $input = $elem;
    $input.keyup(function () {
        var max =  parseInt($input.attr('maxlength'));
        if ($input.val().length > max) {
            $input.val($input.val().substr(0, max));
        }
    });
}

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    var newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
        return key + '=' + encodeURIComponent(params[key]);
    }).join('&');

    return newUrl;
}

/**
 * Extracts all parameters from a given query string into an object
 * @param {string} qs The query string from which the parameters will be extracted
 * @returns {Object} - Object with params from the query string
 */
function getQueryStringParams(qs) {
    if (!qs || qs.length === 0) { return {}; }
    var params = {};
    var unescapedQS = decodeURIComponent(qs);
    // Use the String::replace method to iterate over each
    // name-value pair in the string.
    unescapedQS.replace(new RegExp('([^?=&]+)(=([^&]*))?', 'g'),
        function ($0, $1, $2, $3) {
            params[$1] = $3;
        }
    );
    return params;
}

/**
 * Extracts all parameters from a given query string into an object
 * @param {string} qs The query string from which the parameters will be extracted
 * @returns {Object} - Object with params from the query string
 */
function getParamsFromString(qs) {
    if (!qs || qs.length === 0) { return {}; }
    var params = {};
    var unescapedQS = decodeURIComponent(qs);
    // Use the String::replace method to iterate over each
    // name-value pair in the string.
    unescapedQS.replace(new RegExp('([^?=,]+)(=([^,]*))?', 'g'),
        function ($0, $1, $2, $3) {
            params[$1] = $3;
        }
    );
    return params;
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} message - Error message to display
 */
function createErrorNotification(message) {
    var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
        'fade show" role="alert">' +
        '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
        '<span aria-hidden="true">&times;</span>' +
        '</button>' + message + '</div>';

    $('.cart-error').html(errorHtml);
}

/**
 * Function to handle out of stock swatches color.
 */
function oosColorSwatches(ossColors, colorCodes) {
    colorCodes.removeClass('oss-color');
    var noOfVariationAttr = ossColors.length - 1;
    var isSizesSelected = 0;
    var currentSelection = [];
    var allAvailableColors = [];
    var bothSizesSame = false;
    ossColors.forEach(function (variationAttributes) {
        variationAttributes.values.forEach((valuesitem) => {
            if ((variationAttributes.attributeId !== 'colorCode')) {
                if (valuesitem.selected) {
                    isSizesSelected += 1;
                    currentSelection.push(valuesitem.value);
                }
            }
            if (valuesitem.availableColors && (isSizesSelected === noOfVariationAttr)) {
                colorCodes.addClass('oss-color');
                bothSizesSame = currentSelection[0] === currentSelection[1];
                $.each(valuesitem.availableColors, function (index, availableVariants) {
                    if (noOfVariationAttr === 1) {
                        colorCodes.each(function () {
                            if (($(this).data('attr-value').toString() === availableVariants)) {
                                $(this).removeClass('oss-color');
                            }
                        });
                    } else {
                        var allNumericValues = availableVariants.map(function (unavailableVariant) {
                            var numbers = unavailableVariant.match(/\d+/g);
                            return numbers.map(Number);
                        });

                        var colorSizeObject = {};
                        colorSizeObject[index] = bothSizesSame ? allNumericValues : availableVariants;
                        allAvailableColors.push(colorSizeObject);
                    }
                });
            }
        });
    });

    if (allAvailableColors && allAvailableColors.length > 0 && (isSizesSelected === noOfVariationAttr)) {
        colorCodes.addClass('oss-color');
        $.each(allAvailableColors, function (index, colorSizeObject) {
            var colorCode = Object.keys(colorSizeObject)[0];
            var sizes = colorSizeObject[colorCode];
            var isMatch = sizes.some(function (sizePair) { 
                return bothSizesSame ? currentSelection[0] === sizePair[0].toString() && currentSelection[1] === sizePair[1].toString() : sizePair.includes(currentSelection[0]) && sizePair.includes(currentSelection[1]);
            });
            if (isMatch) {
                colorCodes.each(function () {
                    if ($(this).data('attr-value').toString() === colorCode) {
                        $(this).removeClass('oss-color');
                    }
                });
            }
        });
    }
}


/**
 * Finds an element in the array that matches search parameter
 * @param {array} array - array of items to search
 * @param {function} match - function that takes an element and returns a boolean indicating if the match is made
 * @returns {Object|null} - returns an element of the array that matched the query.
 */
function findItem(array, match) { // eslint-disable-line no-unused-vars
    for (var i = 0, l = array.length; i < l; i++) {
        if (match.call(this, array[i])) {
            return array[i];
        }
    }
    return null;
}

/**
 * Show sucsess message
 * @param {string} message alert message
 * @returns {string} alert markup
 */
function showSuccessMessage(message) {
    return `<div class="alert alert-success order-time-toast text-center" role="alert">
        ${message}
    </div>`;
}

/**
 * Show sucsess message with icon
 * @param {string} message alert message
 * @returns {string} alert markup
 */
function showSuccessMessageWithIcon(message) {
    return `<div class="alert alert-success cancel-order-toast text-center" role="alert">
        <svg class="icon-check"><use xlink:href="#checkmark-red" /></svg>
        ${message}
        <a class="dismiss-alert" href="javascript:void(0)">Dismiss</a>
        </div><br/>`;
}

/**
 * Fadeout animation
 * @param {jQuery} $element jquery element
 * @param {string} speed speed value for
 */
function fadeOut($element, speed = 'slow') {
    // this function will enable future ds motion implemenation
    if ($element) $element.fadeOut(speed);
}

/**
 * Initialize slider
 * @param {jQuery} $elem slide container
 * @param {Object} options Options
 */
function initializeSlider($elem, options) {
    let defaultOptions = {
        lazyLoad: 'progressive',
        autoplay: true,
        speed: 300,
        dots: false,
        infinite: false,
        accessibility: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true
    };

    defaultOptions = Object.assign(defaultOptions, options);

    if ($elem.length) {
        $elem.slick(defaultOptions);
    }
}

/**
 * Function to check whether the component is in viewport and is there required space for navigtaion
 * @param {string} comp footer component
 * @returns {boolean} whether there is space availble for the navigation
 */
function isInViewport(comp) {
    var elementTop = comp.offset().top;
    var elementBottom = elementTop + comp.outerHeight();

    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    if (elementBottom > viewportTop && elementTop < viewportBottom) {
        return elementTop - viewportTop > 155;
    }

    return true;
}

/**
 * Scroll page without animation
 * @param {number} scrollXPos X scroll pos
 * @param {number} scrollYPos Y scroll position
 */
function scrollWithoutAnimation(scrollXPos = 0, scrollYPos = 0) {
    document.documentElement.style.scrollBehavior = 'auto';
    setTimeout(() => window.scrollTo(scrollXPos, scrollYPos), 5);
    setTimeout(() => { document.documentElement.style.scrollBehavior = null; }, 5);
}


/**
 * Close modal
 * @param {Event} e close event
 * @param {DOM} $modal jquery element
 */
// eslint-disable-next-line
function closeModal($modal, event, currentScrollPos = getScrollPos()) {
    let viewPortSize = window.innerWidth;
    if ($modal.hasClass('show')) {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }

        $('html').removeClass('hide-scroll-and-lock');

        if (!$modal.hasClass('size-guide__popup') && !$modal.hasClass('refinebar-modal')) {
            scrollWithoutAnimation(0, currentScrollPos);
        }

        if (viewPortSize <= constants.breakpoints.sm) {
            if ($modal.hasClass('productDetailsModal') || $modal.hasClass('shippingpanelmodal') || $modal.hasClass('reviewsmodal') || $modal.hasClass('qamodal')) {
                $('html').removeClass('lock-body-scroll');
                document.body.style.top = '';
                scrollWithoutAnimation(0, currentScrollPos);
            }
        }

        $modal.removeClass('show');
        $modal.off('hide.bs.modal');

        const hideModal = () => {
            $modal.modal('hide');
        };

        if (!$modal.hasClass('fade')) {
            setTimeout(hideModal, 800);
        } else {
            hideModal();
        }

        $('body').trigger('modal:close');
    }
}

/**
 * Open modal with custom transition
 * @param {DOM} $modal jquery element
 */
function openModal($modal) {
    setScrollPos(window.scrollY);
    let currentScrollPos = getScrollPos();
    let viewPortSize = window.innerWidth;

    if (!$modal.hasClass('fade') && !$modal.hasClass('contentmodal') && !$modal.hasClass('turntomodal')) {
        setTimeout(() => {
            $('html').addClass('hide-scroll-and-lock');
        }, 800);
    } else {
        $('html').addClass('hide-scroll-and-lock');
    }

    if (viewPortSize <= constants.breakpoints.sm) {
        if ($modal.hasClass('productDetailsModal') || $modal.hasClass('shippingpanelmodal') || $modal.hasClass('reviewsmodal') || $modal.hasClass('qamodal')) {
            $('html').addClass('lock-body-scroll');
            document.body.style.top = `-${currentScrollPos}px`;
        }
    }

    $modal.on('hidePrevented.bs.modal', event => {
        closeModal($modal, event);
    });
    $modal.on('hide.bs.modal', event => {
        closeModal($modal, event);
    });

    $modal.modal('show');
}

/**
 * @param {String} message - error message
 */
function showErrorMessage(message) {
    return `<div class="ds-alert ds-alert--error"><span class="icon-alert"></span><span class="ds-alert__message">${message}</span></div>`
}

/**
 * Format zipcode
 * @param {DOM} element jquery element
 */
function formatZip(element) {
    if ($('.page').data('sitename') === 'PVHCKUS' || $('.page').data('sitename') === 'PVHTHUS') {
        element.on('keypress input', function (event) {
            if (element.attr('type') === 'tel') {
                const input = event.target.value.replace(/\D/g, '').substring(0, 9);
                const firstFive = input.substring(0, 5);
                const zipPlusFour = input.substring(5, 9);
                /* eslint-disable */
                if (event.which != 8 && event.which != 0 && (event.which < 48 || event.which > 57)) {
                    event.preventDefault();
                } else {
                    if (input.length > 5) {
                        event.target.value = `${firstFive}-${zipPlusFour}`;
                    } else if (input.length > 0) {
                        event.target.value = `${firstFive}`;
                    }
                }
            } else if (element.attr('data-is-ca-zipcodeformatting') && element.attr('data-is-ca-zipcodeformatting') === 'true') {
                const input = event.target.value.replace(/[^a-zA-Z^0-9]+/g, '').substring(0, 6);
                const firstThree = input.substring(0, 3);
                const lastThree = input.substring(3, 6);
                if (event.which != 5 && event.which != 0 && (event.which < 65 && event.which > 90)) {
                    event.preventDefault();
                } else {
                    if (input.length > 3) {
                        event.target.value = `${firstThree} ${lastThree}`.toUpperCase();
                    } else if (input.length > 0) {
                        event.target.value = `${firstThree}`.toUpperCase();
                    }
                }
            }
        });
    }
}

/**
 * Format order number
 * @param {DOM} element jquery element
 */
function formatOrderNumber(element) {
    element.on('keypress input', function (event) {
        const input = event.target.value;
        event.target.value = input.trim();
    });    
}

/**
* To Remove emoji's from input fields.
* @param {string} str inout field value
* @return {string}  remove emoji's
*/
function removeEmoji(str) {
    let strCopy = str;
    const emojiKeycapRegex = /[\u0023-\u0039]\ufe0f?\u20e3/g;
    const specialCharRegex = /&#[0-9]*;/g;
    //  expression which help remove the iphone emoji's
    const emojiRegex = /\p{Extended_Pictographic}/gu;
    const emojiComponentRegex = /\p{Emoji_Component}/gu;
    if (emojiKeycapRegex.test(strCopy)) {
        strCopy = strCopy.replace(emojiKeycapRegex, '');
    }
    if (specialCharRegex.test(strCopy)) {
        strCopy = strCopy.replace(specialCharRegex, '');
    }
    if (emojiRegex.test(strCopy)) {
        strCopy = strCopy.replace(emojiRegex, '');
    }
    if (emojiComponentRegex.test(strCopy)) {
        // eslint-disable-next-line no-restricted-syntax
        for (const emoji of (strCopy.match(emojiComponentRegex) || [])) {
            if (/[\d|*|#]/.test(emoji)) {
                continue;
            }
            strCopy = strCopy.replace(emoji, '');
        }
    }
    return strCopy;
}

/**
 * Disable CTA for any errors or Null check
 * @param {DOM} button jquery element
 * @param {string} checkValue Value to make CTA disable/enable
 */
function disableButton(buttonElement, checkValue) {
    if (checkValue === null) {
        buttonElement.attr('disabled', true);
    } else {
        buttonElement.removeAttr('disabled');
    }
}

/**
 * Reset PLP scroll details from session store
 */
function resetPLPScrollPos() {
    window.sessionStorage.removeItem('plpUrl');
    window.sessionStorage.removeItem('plpScrollY');
    window.sessionStorage.removeItem('productIndex');
}

/**
 * Check if plp scroll pos exists
 * @returns {boolean} return if plp scroll pos exists
 */
function plpScrollPosExists() {
    return !!window.sessionStorage.getItem('plpScrollY') || false;
}

/**
 * Get current page name
 * @returns {string} page name
 */
function getCurrentPageName() {
    return $('.page').data('page');
}

/**
 * Handle Signin chrome autofill through customization
 */
function formAutofillCustomization() {
    const formElements = {};
    var inputFields;
    const autofillCheckinterval = setInterval(() => {
        inputFields = $('input:-webkit-autofill');
        if (inputFields.val()) {
            clearInterval(autofillCheckinterval);

            inputFields.each(function () {
                const $elem = $(this);
                formElements[$elem.attr('id')] = $elem.val();
                $elem.css('background-color', '#e8f0fe');
                $elem.on('keydown', function () {
                    $(this).css('background-color', '#fff');
                })
            });
        }

        inputFields.on('click', function () {
            const inputValue = $(this).val();
            Object.keys(formElements).map(function (elem) {
                if (inputValue === formElements[elem]) {
                    return $(`#${elem}`).val(formElements[elem]);
                }
            });
        })
    }, 100);

    setTimeout(() => {
        Object.keys(formElements).map(function (elem) {
            return $(`#${elem}`).val(formElements[elem]);
        });
    }, 500);

}

/**
 * Handle Loyalty collapse and expanding loyalty based on checked/unchecked
 */
function loyaltyExpandCollapse() {
    var loyalityDetailsSection = $('#loyaltyDetail'),
        loyalityButton = $('.preferred-loyalty-button');
    if ($('.loyaltyCouponDetails').length > 0) {
        if (loyalityDetailsSection.hasClass('show')) {
            return;
        } else {
            loyalityDetailsSection.addClass('show');
            loyalityButton.removeClass('collapsed');
        }
    } else {
        loyalityDetailsSection.removeClass('show');
        loyalityButton.addClass('collapsed');
    }
}

/**
 * Takes the filters from URL as objects
 * @returns {urlParams} filters in URL as parameters
 */
function getUrlParamAsObject(url) {
    let search = {};
    let urlParams = {};
    let nonPref = {};
    url = new URL(url);
    url.searchParams.forEach((value, key) => {
        if (key.indexOf(preferenceKeyPrefix) !== -1 || key.indexOf(preferenceValuePrefix) !== -1) {
            search[`${key}`] = value.split('|');
        } else {
            nonPref[`${key}`] = value;
        }
    })
    let howManyPref = (Object.keys(search).length) / 2
    for (i = 1; i <= howManyPref; i++) {
        urlParams[search[`${preferenceKeyPrefix}${i}`]] = search[`${preferenceValuePrefix}${i}`];
    }
    return {
        urlParmas: urlParams,
        nonPref: nonPref
    };
}

/**
 * Takes the filters provided in Filter tile
 * @returns {objToUpdate} filters as objects to be appended in URL
 */
function updateURLValues(newValues) {
    var objToUpdate = {};
    if (newValues) {
        objToUpdate = getParamsFromString(newValues);
        Object.entries(objToUpdate).forEach(
            ([key, value]) => objToUpdate[key] = value.split('|')
        );
    }

    return objToUpdate;
}

/**
 * Creates the refinement url with the existing and current filters
 * @returns {UrlParamsToSerialize} returns the modified url
 */
function updateURLParams(urlParamsObj, noPrefObj, updateObj, add) {
    if (add) {
        Object.entries(urlParamsObj).forEach(
            ([key, value]) => {
                if (updateObj[key]) {
                    urlParamsObj[key] = [...value, ...updateObj[key]];
                } else {
                    urlParamsObj[key] = value;
                }
            }
        );
        if (isEmpty(urlParamsObj)) {
            urlParamsObj = merge(urlParamsObj, updateObj)
        } else {
            urlParamsObj = merge(updateObj, urlParamsObj)
        }
    } else {
        Object.entries(urlParamsObj).forEach(
            ([key, value]) => {
                if (updateObj[key]) {
                    let remainingValues = value.filter(function (obj) { return updateObj[key].indexOf(obj) == -1; });
                    if (remainingValues.length) {
                        urlParamsObj[key] = remainingValues;
                    } else {
                        delete urlParamsObj[key];
                    }
                } else {
                    urlParamsObj[key] = value;
                }
            }
        );
    }

    var UrlParamsToSerialize = {};
    let counter = 1;
    for (let key in urlParamsObj) {
        if (key == 'pmin' || key == 'pmax' || key == 'cgid') {
            UrlParamsToSerialize[key] = urlParamsObj[`${key}`][0];
        } else {
            UrlParamsToSerialize[`${preferenceKeyPrefix}${counter}`] = key;
            UrlParamsToSerialize[`${preferenceValuePrefix}${counter}`] = urlParamsObj[`${key}`].join('|');
            counter++;
        }
    }

    UrlParamsToSerialize = merge(UrlParamsToSerialize, noPrefObj);
    return UrlParamsToSerialize;
}

function initializeSlick() {
    $('.categoryList').slick({
        autoplay: false,
        speed: 300,
        dots: false,
        infinite: false,
        accessibility: true,
        slidesToShow: 6,
        adaptiveHeight: true,
        slidesToScroll: 1,
        arrows: true,
        responsive: [
            {
                breakpoint: 7680,
                settings: {
                    slidesToShow: 4,
                    arrows: true
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    swipeToSlide: true,
                    arrows: false
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    swipeToSlide: true,
                    arrows: false
                }
            }
        ],
        prevArrow:
            '<button class="slick-prev slick-arrow" aria-label="Previous" type="button" aria-disabled="false" style=""><span class="icon-arrow-up"></span></button>',
        nextArrow:
            '<button class="slick-next slick-arrow slick-disabled" aria-label="Next" type="button" aria-disabled="true" style=""><span class="icon-arrow-up"></span></button>'
    });
    window.addEventListener('resize', () => {
        $('.categoryList')[0].slick.refresh();
    });
}

function updateCartCount (count) {
    var $miniCart = $('.minicart');
    var $miniCartCountDesktop = $miniCart.find('.total-count-minicart .minicart-number');
    var $miniCartCountMobile = $miniCart.find('.total-count-minicart-mobile .minicart-number');
    var $miniCartStyleMobile = $miniCart.find('.minicart-mobile .icon-cart-container');
    var $miniCartStyleDesktop = $miniCart.find('.language-selctor-enable .icon-cart-container');
    var $cartCount = $miniCart.find('#cartCount');
    if (count > 0) {
        $miniCartCountDesktop.add($miniCartCountMobile).removeClass('mincart-noitems');
        count = count.toString();
        $('.icon-cart-container').html('<span class="icon-bag-filled"> </span>');
        if (count > 99) {
            $miniCartCountDesktop[0].innerText = $miniCartCountMobile[0].innerText = $cartCount[0].value;
            $miniCartCountDesktop.addClass('count-updated-large');
            $miniCartCountMobile.addClass('count-updated-large');
            $miniCartStyleMobile.add($miniCartStyleDesktop).addClass('count-updated-large');
        } else {
            $miniCartCountDesktop[0].innerText = $miniCartCountMobile[0].innerText = count;
            $miniCartCountDesktop.removeClass('count-updated-large');
            $miniCartCountMobile.removeClass('count-updated-large');
            $miniCartStyleMobile.add($miniCartStyleDesktop).removeClass('count-updated-large');
            if(count > 9) {
                $miniCartCountDesktop.addClass('count-updated');
                $miniCartCountMobile.addClass('count-updated');
                $miniCartStyleMobile.add($miniCartStyleDesktop).addClass('count-updated');
            } else {
                $miniCartCountDesktop.removeClass('count-updated');  
                $miniCartCountMobile.removeClass('count-updated'); 
                $miniCartStyleMobile.add($miniCartStyleDesktop).removeClass('count-updated');        
            }
        }
    } else if (count === 0) {
        $miniCartCountDesktop[0].innerText = $miniCartCountMobile[0].innerText = '';
        $miniCartCountDesktop.add($miniCartCountMobile).addClass('mincart-noitems');
    }
}

/**
 * Pluralize string if count is not 1 or -1
 * @param {number} count - count
 * @param {string} str - string to pluralize
 * @returns {string}
 */
function pluralize(count, str) {
    return [1, -1].includes(Number(count)) ? str : str + 's';
}

/**
 * Check repeatation for a string
 * @param {string} str - string to check repeatation
 * @returns {string} returns count
 */
function checkRepeatation(str) {
    /* eslint-disable */
    var counterReached = false;
    for (let i = 0; i < str.length; i++) {
        var charc = str[i];
        if ([...str].filter(item => item === charc).length > 4) {
            counterReached = true;
            break;
        }
    }
    return counterReached;
}

/**
 * Validation on password submission
 * @param {string} passwordValue - password value
 * @returns {Object} returns validation result object
 */
function passwordOnSubmitValidation(passwordValue) {
    /* eslint-disable */
    var valueResult = {
        isFormValid: true,
        errorMessage: []
    };
    function appendValueToResult(error) {
        valueResult.isFormValid = false;
        valueResult.errorMessage.push(error);
    }
    var validLength = passwordValue.length >= 6 ? true : false;
    var validNumbers = passwordValue.match(/[0-9]/g) ? true : false;
    var validLowercaseLetters = passwordValue.match(/[a-z]/g) ? true : false;
    var validUppercaseLetters = passwordValue.match(/[A-Z]/g) ? true : false;
    var validConsecutiveRepeatation = passwordValue.match(/(.)\1{3,}/g) ? true : false;
    var validRepeatation = checkRepeatation(passwordValue);
    if (!validLength) {
        var passwordInvalidlength = $('.passwordInvalidlength').val();
        appendValueToResult(passwordInvalidlength);
    } else {
        var emailValue = $('#registration-form-email').val();
        if (
            !validNumbers &&
            (!validUppercaseLetters || !validLowercaseLetters)
        ) {
            var passwordInvalid = $('.passwordInvalid').val();
            appendValueToResult(passwordInvalid);
        }
        if (!validNumbers && validUppercaseLetters && validLowercaseLetters) {
            var passwordInvalidigits = $('.passwordInvalidigits').val();
            appendValueToResult(passwordInvalidigits);
        }
        if (validNumbers && !validUppercaseLetters && !validLowercaseLetters) {
            var passwordNoUpperlowercaseletters = $('.passwordNoUpperlowercaseletters').val();
            appendValueToResult(passwordNoUpperlowercaseletters);
        }

        if (validNumbers && !validUppercaseLetters && validLowercaseLetters) {
            var passwordNouppercaseletters = $('.passwordNouppercaseletters').val();
            appendValueToResult(passwordNouppercaseletters);
        }
        if (validNumbers && !validLowercaseLetters && validUppercaseLetters) {
            var passwordNolowercaseletters = $('.passwordNolowercaseletters').val();
            appendValueToResult(passwordNolowercaseletters);
        }
        if (emailValue === passwordValue) {
            var passwordSameEmail = $('.passwordSameEmail').val();
            appendValueToResult(passwordSameEmail);
        }
        if (validConsecutiveRepeatation) {
            var passwordConsecutiveRepeatation = $('.passwordConsecutiveRepeatation').val();
            appendValueToResult(passwordConsecutiveRepeatation);
        }
        if (validRepeatation) {
            var passwordRepeatation = $('.passwordRepeatation').val();
            appendValueToResult(passwordRepeatation);
        }
    }
    return valueResult;
}

/**
 * Custom cookie function.
 * @returns {Object} custom cookieset object
 */
function cookieSet() {
    return window.cookieSet || {
        get: function (cookieName) {
            var b = document.cookie.match('(^|;)\\s*' + cookieName + '\\s*=\\s*([^;]+)');
            return b ? b.pop() : '';
        },

        delete: function (name) {
            document.cookie = '{0}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
                .replace('{0}', name);
        },

        set: function (name, value) {
            document.cookie =
                '{0}={1};expires=Fri, 31 Dec 9999 23:59:59 GMT;path=/;SameSite=Lax'
                    .replace('{0}', name)
                    .replace('{1}', value);
        }
    };
}

/**
 * Get image with format appended/replaced
 * @param {String} imageUrl imageurl to change
 * @param {String} format image format to append to image
 * @returns {String}
 */
function getImageUrlWithFormat(imageUrl, format) {
    try {
        let url = imageUrl;
        if (format) {
            url = new URL(imageUrl);
            url.searchParams.delete('fmt');
            url.searchParams.append('fmt', format);
        }

        return url.toString();
    } catch (error) {
        return imageUrl;
    }
}

/**
 * Generate Image sourceset for picture tag based on image and dimensions
 * @param {Object} image image object
 * @param {Object} imageDimentions image dimension object from BM site pref
 * @param {Object} type type of the configuration to pick, ex : plp, pdp
 * @returns {Array} sources
 */
function generateImageSourceSet(image, options) {
    if (!image) return [];

    if(!options) {
        console.error('Cannot render picture tag without options');
        return false;
    }

    const imageURL = image.url;
    const imagePixelRatio = constants.image.SUPPORTED_PIXEL_RATIO;
    const sources = [];
    const srcsetAttr = options.lazyload ? 'data-srcset' : 'srcset';

    // picked from constants, new format can be added to update sourceset
    const imageFormats = constants.image.SUPPORTED_FORMATS;

    // list of devices and its breakpoint used to create a source set
    const supportedDevices = constants.image.SUPPORTED_DEVICES;

    // responsive image set which has 1x and 2x sizes
    let resImageConfig = {};
    if (options.devices.desktop) {
        resImageConfig['desktop'] = {
            1: `${imageURL}${options.devices.desktop['1']}`,
            2: `${imageURL}${options.devices.desktop['2']}`
        }
    }

    if (options.devices.tablet) {
        resImageConfig['tablet'] = {
            1: `${imageURL}${options.devices.tablet['1']}`,
            2: `${imageURL}${options.devices.tablet['2']}`
        }
    }

    if (options.devices.mobile) {
        resImageConfig['mobile'] = {
            1: `${imageURL}${options.devices.mobile['1']}`,
            2: `${imageURL}${options.devices.mobile['2']}`
        }
    }

    // Loop through the required devices and formats to create sourceset for picture tag
    Object.entries(supportedDevices).map(device => {
        if (!device) return;
        const deviceName = device[0].toLowerCase();

        if (resImageConfig[deviceName]) {
            imageFormats.map(format => {
                if (!format) return;
                sources.push(`<source
                    ${srcsetAttr}="${getImageUrlWithFormat(resImageConfig[deviceName][1], format)} ${imagePixelRatio[0]},
                                    ${getImageUrlWithFormat(resImageConfig[deviceName][2], format)}  ${imagePixelRatio[1]}"
                    media="${device[1]}"
                    type="image/${format}">`);
            });
        }
    });

    return sources;
}

/**
 * RenderPictureTag create picture tag for given image
 * @param {Object} image image object
 * @param {Object} imageDimentions Image dimensions
 * @param {String} type image type ex plp , pdp etc
 * @returns {Srtring} Markup
 */
function renderPictureTag(image, options) {
    const sourceSet = generateImageSourceSet(image, options);
    const imageUrl = options.lazyload ? constants.image.FALLBACK : image.url;
    let className = image.imageClassname ? image.imageClassname : '';
    className = `${className} ${options.lazyload ? 'lazy' : ''}`
    // const imageUrl = image.lazyload ? constants.image.FALLBACK : image.url;
    return `<picture>
        ${sourceSet.join('')}
        <img onerror="onImageError($(this));"
            alt="${image.alt}"
            itemprop="image"
            title="${image.title}"
            src="${imageUrl}"
            class="${className}"
            ${options.lazyload ? 'loading="lazy"' : ''}
            ${options.target ? 'data-target="#' + options.target + '"' : ''}
            ${options.imageindex >= 0 ? 'data-imageindex="' + options.imageindex + '"' : ''}
            ${options.iscolab ? 'data-iscolab="' + options.iscolab + '"' : ''}
            ${options.toggle ? 'data-toggle="' + options.toggle + '"' : ''}
            />
    </picture>`;
}

/**
 * lazyLoadImages based on given target for both image and picture tag
 * @param {jQuery}  $target target object to find images
 */
function lazyLoadImage($target){
    const $images = $target.find('img.lazy:not(.loaded)');

    // this would work for picture tag
    const $sourceTags = $images.siblings('source');

    if ($sourceTags.length) {
        // eslint-disable-next-line no-param-reassign
        $sourceTags.map((index, source) => {
            if (source.dataset.srcset) {
                source.srcset = source.dataset.srcset
            }
        });
    }

    $images.each(function (index, item) {
        // eslint-disable-next-line
        if (item.dataset.src) item.src = item.dataset.src;
        item.classList.add('loaded');
    });
}

/**
 * Intersection observer for image load
 */
 function imageIntersectionObserver (elmSelector) {
    if (elmSelector) {
        var options = {
            root: null,
            rootMargin: '0px',
            threshold: 0
        };

        function handleIntersect(entries) {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    lazyLoadImage($(entry.target));
                }
            });
        }
        let productImageObserver = new IntersectionObserver(handleIntersect, options);
        var productImages = document.querySelectorAll(elmSelector);
        productImages.forEach(function (productImage) {
            productImageObserver.observe(productImage);
        });
    }
}

/**
 * Generate csrf token and update the element value
 * @param {String}  generateTokenUrl Token URL
 * @param {Function}  errorHandling Error handling function for the event error block
 */
function handleCsrfToken(errorHandling) {
    let generateTokenUrl = $('#generate-csrf-token-url').val();
    let generateCsrfToken = new Promise(function (resolve, reject) {
        $.ajax({
            url: generateTokenUrl,
            method: 'POST',
            success: function (data) {
                resolve(data);
            },
            error: function (err) {
                reject(err);
            }
        });
    });
    generateCsrfToken.then(function (data) {
        let csrfToken = data.csrf.token;
        $('.csrf-token').attr('data-csrf-value', csrfToken);
        errorHandling();
    }).catch(function (err) {
        errorHandling();
    });
}


module.exports = {
    // Handle broken image and replace with image skeleton.
    onImageError: (e) => {
        e.attr('onError', null)
        e.attr('src', 'data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAFCAQAAADIpIVQAAAAEUlEQVR42mP89Z8BDBiJYAAA/CsJ44FW0NkAAAAASUVORK5CYII=');
        let picTag = e.closest('picture');
        if (picTag.length) {
            picTag.find('source').attr('srcset', 'data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAFCAQAAADIpIVQAAAAEUlEQVR42mP89Z8BDBiJYAAA/CsJ44FW0NkAAAAASUVORK5CYII=');
        }
        e.attr('alt', '');
        e.attr('title', '');
    },
    /* eslint-disable one-var */
    isTHSite: () => ($('.page').hasClass('pvhthus') || $('.page').hasClass('pvhthca'))  ? true : false,
    isDesktop: () => !(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) && !(navigator.maxTouchPoints &&
        navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform)),
    isMobileScreenSize: (size) => {
        if (size >= constants.breakpoints.sm) {
            return false;
        }
        return true;
    },
    isiOS: () => {
        return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
            // iPad on iOS 13 detection
            || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
    },
    getCurrentBreakpoint: () => {
        try {
            return window.getComputedStyle(window.document.querySelector('body'), ':before')
                .getPropertyValue('content').replace(/"/g, '') || 'desktop';
        } catch (error) {
            return 'desktop';
        }
    },
    showErrorToast: (message, $target, sticky = false) => {
        if (!message) return;
        $('body').trigger('notification:close');
        const $targetElm = $target || $('.ds-globalnav-header');
        const classNames = sticky ? 'is-sticky' : '';
        const toastMarkup = `<div class="ds-toast ds-toast--error ${classNames} open">
                                <span class="ds-toast__icon">
                                    <svg class="icon-error">
                                        <use xlink:href="#alert-notifcation"></use>
                                    </svg>
                                </span>
                                <span class="ds-toast__message"></span>
                                <a class="ds-toast__dismiss" data-close href="javascript:void(0);">Dismiss</a>
                            </div>`;


        $targetElm.remove('.ds-toast');
        $targetElm.append(toastMarkup);
        $targetElm.find('.ds-toast__message').text(message);
        var $newToast = $targetElm.find('.ds-tosast');
        $newToast.trigger('focus');
    },

    showConfirmToast: (message) => {
        if (!message) return;
        const $targetElm = $('#maincontent');
        var isTHSite = ($('.page').hasClass('pvhthus') || $('.page').hasClass('pvhthca')) ? true : false;
        var hideToastMessage = parseInt($('.global-footer').data('hidetoastmessage') || constants.NOTIFICATION_DEFAULT_TIMEOUT);
        const toastMarkup = `<div class="ds-toast ds-toast--success open" role="status">
                                <span class="ds-toast__icon">
                                    <svg class="icon-success">
                                        <use xlink:href=${isTHSite ? "#check-mark-success-solid" : "#check-mark-success"}></use>
                                    </svg>
                                </span>
                                <span class="ds-toast__message"></span>
                            </div>`;

        $targetElm.find('.ds-toast').remove('.ds-toast');

        if(toastTimeoutInstance) clearTimeout(toastTimeoutInstance);
        $('body').trigger('notification:close');
        toastTimeoutInstance = setTimeout(() => {
            $('body').trigger('notification:close', [true]);
        }, hideToastMessage);

        $targetElm.append(toastMarkup);
        // Safely inject the message as plain text
        $targetElm.find('.ds-toast__message').text(message);
        var $newToast = $targetElm.find('.ds-toast');
        if ($('.checkoutButtonWrapper').hasClass('stickyCheckoutButton')) {
            $('.ds-toast--success').css('bottom', '112px');
        } else {
            $('.ds-toast--success').css('bottom', '24px');
        }
        $newToast.trigger('focus');
    },

    showSuccessAlert: (message, $target, withIcon = true) => {
        if (!message) return;
        const iconMarkup = `<span class="ds-toast__icon">
            <svg class="icon-success">
                <use xlink:href="#alert-notifcation"></use>
            </svg>
        </span>`;
        const alertMarkup = `<div class="ds-alert ds-alert--success">
                                ${withIcon && iconMarkup}
                                <span class="ds-alert__message">${message}</span>
                            </div>`;
        $target.remove('.ds-alert');
        $target.append(alertMarkup);
        $('.ds-alert').trigger('focus');
    },

    showErrorAlert: (message, $target, withIcon = true) => {
        if (!message) return;
        const iconMarkup = `<span class="ds-toast__icon">
                <svg class="icon-error">
                    <use xlink:href="#alert-notifcation"></use>
                </svg>
            </span>`;
        const alertMarkup = `<div class="ds-alert ds-alert--error">
                                ${withIcon && iconMarkup}
                                <span class="ds-alert__message">${message}</span>
                            </div>`;
        $target.remove('.ds-alert');
        $target.append(alertMarkup);
    },

    captureFocusInElement: ($element) => {
        const focusableElements =
            'button:visible, [href]:visible, input:visible, select:visible, textarea:visible, [tabindex]:not([tabindex="-1"]):visible';

        const firstFocusableElement = $element.find(focusableElements)[0];
        const focusableContent = $element.find(focusableElements);
        const lastFocusableElement = focusableContent[focusableContent.length - 1];

        let isFirstElementFocused = false;
        document.addEventListener('keydown', function (e) {
            let isTabPressed = e.key === 'Tab' || e.keyCode === 9;

            if (!isTabPressed) {
                return;
            }

            if (document.activeElement !== firstFocusableElement && !isFirstElementFocused) {
                firstFocusableElement.focus();
                isFirstElementFocused = true;
            }

            if (e.shiftKey) {
                if (document.activeElement === firstFocusableElement) {
                    lastFocusableElement.focus();
                    e.preventDefault();
                }
            } else if (document.activeElement === lastFocusableElement) {
                firstFocusableElement.focus();
                e.preventDefault();
            }
        });
    },

    /**
     * Check for session timeout by making an ajax call
     * @returns {Object} returns the session details
     */
    sessionTimeoutCheck: function () {
        let result = {
            sessionTimeout: false
        };
        if ($('.checkout-sessionTimeout-url').length) {
            $.ajax({
                url: $('.checkout-sessionTimeout-url').val(),
                type: 'get',
                dataType: 'json',
                async: false,
                success: function (data) {
                    if (!data.error && data.sessionTimeout) {
                        result.sessionTimeout = data.sessionTimeout;
                        result.redirectUrl = data.redirectUrl;
                        result.registered = data.registered;
                    }
                }
            });
        }
        return result;
    },

    /**
     * Update Query param if session is timed out when express pay button is clicked
     * @returns {boolean} returns true if session is expired
     */
    expressButtonSessionTimeout: function () {
        // Checking for session timeout when express pay is clicked
        var sessionTimeoutCheck = this.sessionTimeoutCheck();
        var sessionTimeoutUrl = sessionTimeoutCheck.redirectUrl;

        if (sessionTimeoutCheck.sessionTimeout) {
            if (sessionTimeoutCheck.registered) {
                var query = new URLSearchParams();
                query.append('paymentMethod', 'expressPayment');
                sessionTimeoutUrl = `${sessionTimeoutCheck.redirectUrl}&${query.toString()}`;
            }
            window.location.href = sessionTimeoutUrl;
            return true;
        } else {
            return false;
        }
    },
    editShippingAddressFormOpen: function (userType, addressId) {
        var $SshipAddrBlock = $('.shipping-address-block');
        var $SshipAddrSavedBlock = $('.single-shipping .shipping-address-saved-block');
        var $SbtnCancel = $('.single-shipping .btn-cancel');
        var $SshipToSelector = $('.single-shipping  .addressSelector.ship-to-selector');
        var $SbtnShowDtls = $('.single-shipping .btn-show-details');
        var $SaddEditShip = $('.single-shipping .add-edit-shipping');
        var $SsubmitToPayment = $('.shipping-submit-to-payment-button');
        var $SshipRadio = $('.custon-shipping-radio');
        var $editShippingSelector = $('.shipping-address-selector-block');
        var $SsaveAndContBtn = $('.single-shipping .save-continue-button');
        var $singleShippingForm = $('.single-shipping .shipping-form-addressverify');
        const validator = require('../js/validator');
        var scrollAnimate = require('../js/components/scrollAnimate');
        scrollAnimate($('body'), 0);
        if (userType === 'guest' && addressId === 'new') {
            $SshipAddrBlock.addClass('show');
            $SshipAddrSavedBlock.removeClass('show');
        } else {
            $SbtnCancel.attr('data-address-id', addressId);
            $SshipToSelector.val(addressId).trigger('change');
            $SbtnShowDtls.trigger('click');
            $SshipAddrBlock.addClass('show');

            $SaddEditShip.addClass('d-none').removeClass('show');
            $SsubmitToPayment.prop('disabled', true);
            $SshipRadio.prop('disabled', true);
            $SshipAddrSavedBlock.removeClass('show');
            $('#addressBookModal').modal('hide');
            $editShippingSelector.addClass('d-none').removeClass('show');
            $('.shipping-message').removeClass('d-none');
            validator.initForm($singleShippingForm);
        }
        var $privacyPolicy = $('#ds-privacy-policy');
        if ($privacyPolicy.length) {
            var optMail = cookieSet().get('optMailCheckout') === 'true' || cookieSet().get('optMailCheckout') === '' ? true : false;
            $privacyPolicy.prop('checked', optMail);
        }
        $SsubmitToPayment.prop('disabled', true);
        $SsaveAndContBtn.attr('data-save-address', userType === 'guest' ? 'false' : 'true');
        $SsaveAndContBtn.attr('data-address-id', userType === 'guest' ? '' : addressId.substring(3, addressId.length));
        $SshipRadio.prop('disabled', true);
        $('input[name="dwfrm_shipping_shippingAddress_shippingMethodID"]').attr('disabled', true);
    },

    insertCharacterAtSpecificPosition: (str, characterToInsert, positionToInsert) =>
        `${str.slice(0, positionToInsert)}${characterToInsert}${str.slice(positionToInsert)}`,

    appendToUrl,
    showSuccessMessage,
    showSuccessMessageWithIcon,
    fadeOut,
    initializeSlider,
    isInViewport,
    getQueryStringParams,
    getParamsFromString,
    createErrorNotification,
    findItem,
    closeModal,
    openModal,
    showErrorMessage,
    formElementLimitCheck,
    lockPage,
    unlockPage,
    scrollWithoutAnimation,
    isTouchEnabled,
    formatZip,
    formatOrderNumber,
    removeEmoji,
    setWindowHeight,
    disableButton,
    resetPLPScrollPos,
    plpScrollPosExists,
    getCurrentPageName,
    getUrlParamAsObject,
    updateURLValues,
    updateURLParams,
    formAutofillCustomization,
    loyaltyExpandCollapse,
    toggleInteractionMode,
    initializeSlick,
    updateCartCount,
    pluralize,
    checkRepeatation,
    passwordOnSubmitValidation,
    cookieSet,
    generateImageSourceSet,
    renderPictureTag,
    lazyLoadImage,
    imageIntersectionObserver,
    handleCsrfToken,
    oosColorSwatches
};